import { FC, ReactElement } from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import {
    Control,
    Controller,
    FieldValues,
    RegisterOptions,
    UseFormSetValue,
} from 'react-hook-form';

import { useTranslate } from 'hooks';

import { BSBadge } from '../Badge';
import { MultiSelect } from '../MultiSelect';
import { SVGIcon } from '../SVGIcon';

export interface SelectOption {
    label: string;
    value: string | number;
    disabled?: boolean;
}

export type FormControlType =
    | 'text'
    | 'select'
    | 'textarea'
    | 'date'
    | 'dateTime' // ** Use 'datetime-local' instead.
    | 'datetime-local'
    | 'file'
    | 'checkbox'
    | 'radio'
    | 'switch'
    | 'number'
    | 'singleSelect' // ** Use 'select' instead.
    | 'checkboxMulti';
// ** This is added to support VBC config data field types.

export interface FormControlProps {
    control: Control<FieldValues, any>;
    controlId: string;
    label?: string;
    defaultValue?: any;
    isInvalid?: boolean;
    isValid?: boolean;
    placeholder?: string;
    invalidFeedback?: string;
    validFeedback?: string;
    helpText?: string;
    className?: string;
    // from react-hook-form
    // prettier-ignore
    rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    type?: FormControlType;
    onChange?: (value: any) => void;
    disabled?: boolean;
    loading?: boolean;
    selectOptions?: SelectOption[];
    textareaRows?: number;
    setValueFn: UseFormSetValue<FieldValues>;
    badgeText?: string;
    checkboxLabel?: string;
    acceptFiles?: string;
    multiselectMin?: number;
    maxDate?: string;
    minDate?: string;
    checkboxMultiSearchable?: boolean;
    checkboxMultiCreatable?: boolean;
    checkboxMultiOnAddCustomValue?: (newValue: string) => void;
    multipleFiles?: boolean;
}

const inputTypeMap = {
    text: 'text',
    select: 'select',
    singleSelect: 'select',
    textarea: 'textarea',
    file: 'file',
    checkbox: 'checkbox',
    radio: 'radio',
    switch: 'switch',
    number: 'number',
    date: 'date',
    dateTime: 'datetime-local',
    'datetime-local': 'datetime-local',
    checkboxMulti: 'checkboxMulti',
};

const MULTI_SELECT_MIN_SELECTION = 1;

const calculateTextAreaRows = (text: string): number => Math.min((text ?? '').length / 30, 10);

export const FormControl: FC<FormControlProps> = ({
    control,
    controlId,
    label,
    defaultValue,
    isInvalid,
    isValid,
    placeholder,
    invalidFeedback,
    validFeedback,
    helpText,
    type = 'text',
    rules,
    onChange: triggerChange,
    disabled,
    loading,
    className = '',
    selectOptions,
    textareaRows,
    setValueFn,
    badgeText,
    checkboxLabel,
    acceptFiles,
    multiselectMin = MULTI_SELECT_MIN_SELECTION,
    maxDate,
    minDate,
    checkboxMultiSearchable,
    checkboxMultiCreatable,
    checkboxMultiOnAddCustomValue,
    multipleFiles = false,
}): ReactElement => {
    const { t } = useTranslate();
    const allowedFileExtentions = acceptFiles?.split(',').map((ext) => ext.trim());
    const multiselectRules =
        type === 'checkboxMulti' && rules?.required
            ? {
                  ...rules,
                  validate: {
                      selectAtLeast: (selectedOptions: SelectOption[]) => {
                          if (selectedOptions.length < multiselectMin) {
                              return `Select at least ${multiselectMin} option${
                                  multiselectMin > 1 ? 's' : ''
                              }`;
                          }

                          return true;
                      },
                  },
              }
            : rules;
    const formControlRules =
        type === 'file' && allowedFileExtentions?.length
            ? {
                  ...rules,
                  validate: {
                      fileExtensionValid: (files: FileList | File[] | string) => {
                          if (files && multipleFiles) {
                              const fileArray = Array.isArray(files)
                                  ? files
                                  : Array.from(files as FileList);
                              const isValid = fileArray.every((file) =>
                                  allowedFileExtentions.some((ext) =>
                                      file.name.toLocaleLowerCase().endsWith(ext)
                                  )
                              );

                              return (
                                  isValid ||
                                  t.FILE_EXTENSION_VALIDATION_ERROR?.replace(
                                      /__EXTENSIONS__/,
                                      acceptFiles as string
                                  )
                              );
                          } else {
                              if (files) {
                                  return (
                                      allowedFileExtentions.some((ext) =>
                                          (files as string).toLocaleLowerCase().endsWith(ext)
                                      ) ||
                                      t.FILE_EXTENSION_VALIDATION_ERROR?.replace(
                                          /__EXTENSIONS__/,
                                          acceptFiles as string
                                      )
                                  );
                              }
                          }

                          return true;
                      },
                  },
              }
            : rules;

    return (
        <Form.Group
            className={`${className} ${
                type === 'file' ? 'file-input' : ''
            } form-group position-relative static-input`}
            controlId={controlId}
        >
            {label && (
                <Form.Label className="d-flex pe-none position-absolute top-0 start-0 w-100 text-pre">
                    {label} {rules?.required && <span className="mandatory text-danger">*</span>}
                    {badgeText && (
                        <BSBadge fill className="custom-badge ms-auto" badgeText={badgeText} />
                    )}
                </Form.Label>
            )}
            <Controller
                control={control}
                key={controlId}
                name={controlId}
                defaultValue={defaultValue}
                rules={{ ...formControlRules, ...multiselectRules }}
                render={({ field: { onChange, value, ref, onBlur } }) => {
                    const valueProp = { value: value || '' };
                    const formControlCommonProps = {
                        type: inputTypeMap[type],
                        ref,
                        isInvalid,
                        isValid: isValid && value,
                        placeholder: placeholder || label,
                        disabled,
                    };
                    const formControlOnChangeProp = {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange: (e: any) => {
                            onChange(e);
                            triggerChange &&
                                triggerChange(
                                    type === 'file'
                                        ? (e?.target as HTMLInputElement)?.files
                                        : e?.currentTarget.value
                                );
                        },
                    };
                    const formControlOnBlurProp = {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onBlur: (e: any) => {
                            onBlur();
                            setValueFn(controlId, e?.currentTarget.value.trim(), {
                                shouldValidate: true,
                            });
                        },
                    };
                    const plainInputTypes = [
                        'text',
                        'number',
                        'date',
                        'dateTime',
                        'datetime-local',
                    ];
                    const selectInputTypes = ['select', 'singleSelect'];
                    return (
                        <div className="position-relative pt-32">
                            {plainInputTypes.includes(type) && (
                                <Form.Control
                                    {...valueProp}
                                    {...formControlOnChangeProp}
                                    {...formControlOnBlurProp}
                                    {...formControlCommonProps}
                                    max={maxDate ? maxDate : undefined}
                                    min={minDate ? minDate : undefined}
                                />
                            )}
                            {selectInputTypes.includes(type) && (
                                <Form.Select
                                    {...valueProp}
                                    {...formControlOnChangeProp}
                                    {...formControlCommonProps}
                                >
                                    {selectOptions?.map(({ value, label, disabled = false }) => (
                                        <option key={value} value={value} disabled={disabled}>
                                            {label}
                                        </option>
                                    ))}
                                </Form.Select>
                            )}
                            {type === 'textarea' && (
                                <Form.Control
                                    as={type}
                                    rows={textareaRows || calculateTextAreaRows(value)}
                                    {...valueProp}
                                    {...formControlOnChangeProp}
                                    {...formControlOnBlurProp}
                                    {...formControlCommonProps}
                                />
                            )}
                            {type === 'file' && (
                                <InputGroup>
                                    <InputGroup.Text>
                                        <SVGIcon icon="UploadIcon" className="svg-icon" />
                                    </InputGroup.Text>
                                    <Form.Control
                                        multiple={multipleFiles}
                                        accept={acceptFiles}
                                        {...formControlOnChangeProp}
                                        {...formControlCommonProps}
                                    />
                                </InputGroup>
                            )}
                            {(type === 'checkbox' || type === 'switch') && (
                                <Form.Check
                                    checked={value}
                                    label={
                                        checkboxLabel && (
                                            <>
                                                {checkboxLabel}
                                                {rules?.required && (
                                                    <span className="mandatory text-danger">*</span>
                                                )}
                                            </>
                                        )
                                    }
                                    {...valueProp}
                                    {...formControlCommonProps}
                                    {...formControlOnChangeProp}
                                    type={type}
                                />
                            )}

                            {type === 'checkboxMulti' && (
                                <MultiSelect
                                    options={selectOptions || []}
                                    controlId={controlId}
                                    onSelectOptions={(selected: SelectOption[]) => {
                                        setValueFn(controlId, selected, {
                                            shouldTouch: true,
                                            shouldDirty: true,
                                        });
                                    }}
                                    invalidFeedback={invalidFeedback}
                                    disabled={disabled}
                                    searchable={checkboxMultiSearchable}
                                    creatable={checkboxMultiCreatable}
                                    onAddCustomOption={checkboxMultiOnAddCustomValue}
                                />
                            )}

                            {loading && (
                                <Spinner className="input-spinner" size="sm" variant="primary" />
                            )}

                            {invalidFeedback && (
                                <Form.Control.Feedback className="text-start" type="invalid">
                                    <>{invalidFeedback}</>
                                </Form.Control.Feedback>
                            )}
                            {validFeedback && (
                                <Form.Control.Feedback className="text-start" type="valid">
                                    <>{validFeedback}</>
                                </Form.Control.Feedback>
                            )}
                            {!invalidFeedback && !validFeedback && helpText && (
                                <Form.Label className="text-secondary mt-1 mb-0 pe-none">
                                    {helpText}
                                </Form.Label>
                            )}
                        </div>
                    );
                }}
            />
        </Form.Group>
    );
};
