import { createSlice } from '@reduxjs/toolkit';

interface ReloadState {
    myAppsReload: number;
    customersListReload: number;
    usersListReload: number;
    orgGroupsListReload: number;
    invitedUsersListReload: number;
    participantsListReload: number;
    healthPlansListReload: number;
    organizationListReload: number;
    organizationRolesReload: number;
    reloadOrganizationDetails: number;
    participantPoliciesReload: number;
    pendingPoliciesCountReload: number;
    pendingBAAListReload: number;
    pendingReviewCountReload: number;
    allClaimsListReload: number;
    claimReportsListReload: number;
    customerAppsReload: number;
    outcomesReportsListReload: number;
    dataIntakeFeatureDataReload: number;
    vbcAppConfigReload: number;
    vbcMonitoringReportReload: number;
    draftClaimsListReload: number;
    healthPlansUploadStatusListReload: number;
    vbcCampaignListReload: number;
    vbcMarketplacePayerOffersReload: number;
    vbcSupportingInfoReload: number;
    allTPAsListReload: number;
    syntheticVBCListReload: number;
    tpaClaimsListReload: number;
    allTPAPatientsListReload: number;
    drugFailureReportsListReload: number;
    notificationsListReload: number;
    allUsersListReload: number;
    allContractsListReload: number;
}

const initialState: ReloadState = {
    myAppsReload: 0,
    customersListReload: 0,
    usersListReload: 0,
    orgGroupsListReload: 0,
    invitedUsersListReload: 0,
    participantsListReload: 0,
    healthPlansListReload: 0,
    organizationListReload: 0,
    organizationRolesReload: 0,
    reloadOrganizationDetails: 0,
    participantPoliciesReload: 0,
    pendingPoliciesCountReload: 0,
    pendingBAAListReload: 0,
    pendingReviewCountReload: 0,
    allClaimsListReload: 0,
    claimReportsListReload: 0,
    customerAppsReload: 0,
    outcomesReportsListReload: 0,
    dataIntakeFeatureDataReload: 0,
    vbcAppConfigReload: 0,
    vbcMonitoringReportReload: 0,
    draftClaimsListReload: 0,
    healthPlansUploadStatusListReload: 0,
    vbcCampaignListReload: 0,
    vbcMarketplacePayerOffersReload: 0,
    vbcSupportingInfoReload: 0,
    allTPAsListReload: 0,
    syntheticVBCListReload: 0,
    tpaClaimsListReload: 0,
    allTPAPatientsListReload: 0,
    drugFailureReportsListReload: 0,
    notificationsListReload: 0,
    allUsersListReload: 0,
    allContractsListReload: 0,
};

export const reloadSlice = createSlice({
    name: 'reload',
    initialState,
    reducers: {
        reloadMyApps: (state) => {
            state.myAppsReload = state.myAppsReload + 1;
        },
        reloadCustomersList: (state) => {
            state.customersListReload = state.customersListReload + 1;
        },
        reloadUsersList: (state) => {
            state.usersListReload = state.usersListReload + 1;
        },
        reloadOrgGroupsList: (state) => {
            state.orgGroupsListReload = state.orgGroupsListReload + 1;
        },
        reloadInvitedUsersList: (state) => {
            state.invitedUsersListReload = state.invitedUsersListReload + 1;
        },
        reloadParticipantsList: (state) => {
            state.participantsListReload = state.participantsListReload + 1;
        },
        reloadHealthPlansList: (state) => {
            state.healthPlansListReload = state.healthPlansListReload + 1;
        },
        reloadOrganizationsList: (state) => {
            state.organizationListReload = state.organizationListReload + 1;
        },
        reloadOrganizationRoles: (state) => {
            state.organizationRolesReload = state.organizationRolesReload + 1;
        },
        reloadOrganizationDetails: (state) => {
            state.reloadOrganizationDetails = state.reloadOrganizationDetails + 1;
        },
        reloadParticipantPolicies: (state) => {
            state.participantPoliciesReload = state.participantPoliciesReload + 1;
        },
        reloadPendingPoliciesCount: (state) => {
            state.pendingPoliciesCountReload = state.pendingPoliciesCountReload + 1;
        },
        reloadPendingBAAList: (state) => {
            state.pendingBAAListReload = state.pendingBAAListReload + 1;
        },
        reloadPendingReviewCount: (state) => {
            state.pendingReviewCountReload = state.pendingReviewCountReload + 1;
        },
        reloadAllClaimsList: (state) => {
            state.allClaimsListReload = state.allClaimsListReload + 1;
        },
        reloadClaimsReportsList: (state) => {
            state.claimReportsListReload = state.claimReportsListReload + 1;
        },
        reloadCustomerAppsList: (state) => {
            state.customerAppsReload = state.customerAppsReload + 1;
        },
        reloadOutcomesReportList: (state) => {
            state.outcomesReportsListReload = state.outcomesReportsListReload + 1;
        },
        reloadDataIntakeFeatureData: (state) => {
            state.dataIntakeFeatureDataReload = state.dataIntakeFeatureDataReload + 1;
        },
        reloadVBCAppConfig: (state) => {
            state.vbcAppConfigReload = state.vbcAppConfigReload + 1;
        },
        reloadVBCMonitoringReport: (state) => {
            state.vbcMonitoringReportReload = state.vbcMonitoringReportReload + 1;
        },
        reloadDraftClaimsList: (state) => {
            state.draftClaimsListReload = state.draftClaimsListReload + 1;
        },
        reloadHealthPlansUploadStatusListReload: (state) => {
            state.healthPlansUploadStatusListReload = state.healthPlansUploadStatusListReload + 1;
        },
        reloadVBCCampaignList: (state) => {
            state.vbcCampaignListReload = state.vbcCampaignListReload + 1;
        },
        reloadVBCMarketplacePayerOffersList: (state) => {
            state.vbcMarketplacePayerOffersReload = state.vbcMarketplacePayerOffersReload + 1;
        },
        reloadVBSupportingInfoList: (state) => {
            state.vbcSupportingInfoReload = state.vbcSupportingInfoReload + 1;
        },
        reloadAllTPAsList: (state) => {
            state.allTPAsListReload = state.allTPAsListReload + 1;
        },
        reloadSyntheticVBCList: (state) => {
            state.syntheticVBCListReload = state.syntheticVBCListReload + 1;
        },
        reloadTPAClaimsList: (state) => {
            state.tpaClaimsListReload = state.tpaClaimsListReload + 1;
        },
        reloadTPAPatientsList: (state) => {
            state.allTPAPatientsListReload = state.allTPAPatientsListReload + 1;
        },
        reloadDrugFailureReportsList: (state) => {
            state.drugFailureReportsListReload = state.drugFailureReportsListReload + 1;
        },
        reloadNotificationsList: (state) => {
            state.notificationsListReload = state.notificationsListReload + 1;
        },
        reloadAllUsersList: (state) => {
            state.allUsersListReload = state.allUsersListReload + 1;
        },
        reloadAllContractsList: (state) => {
            state.allContractsListReload = state.allContractsListReload + 1;
        },
    },
});

export const {
    reloadMyApps,
    reloadCustomersList,
    reloadUsersList,
    reloadOrgGroupsList,
    reloadInvitedUsersList,
    reloadParticipantsList,
    reloadHealthPlansList,
    reloadOrganizationsList,
    reloadOrganizationRoles,
    reloadOrganizationDetails,
    reloadParticipantPolicies,
    reloadPendingPoliciesCount,
    reloadPendingBAAList,
    reloadPendingReviewCount,
    reloadAllClaimsList,
    reloadClaimsReportsList,
    reloadCustomerAppsList,
    reloadOutcomesReportList,
    reloadDataIntakeFeatureData,
    reloadVBCAppConfig,
    reloadVBCMonitoringReport,
    reloadDraftClaimsList,
    reloadHealthPlansUploadStatusListReload,
    reloadVBCCampaignList,
    reloadVBCMarketplacePayerOffersList,
    reloadVBSupportingInfoList,
    reloadAllTPAsList,
    reloadSyntheticVBCList,
    reloadTPAClaimsList,
    reloadTPAPatientsList,
    reloadDrugFailureReportsList,
    reloadNotificationsList,
    reloadAllUsersList,
    reloadAllContractsList,
} = reloadSlice.actions;

export default reloadSlice.reducer;
