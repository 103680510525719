import { useNavigate } from 'react-router-dom';

import { AppConstant } from 'common';
import { APP_TYPES } from 'features/customers/types';

import useAppSelector from './useAppSelector';

const useNavigateToVBCApp = () => {
    const navigate = useNavigate();
    const { myApps } = useAppSelector((state) => state.platformApps);
    const { userAccount } = useAppSelector((state) => state.auth);

    const navigateToVBCApp = (appId: number, tenantId: string, appType: string = APP_TYPES.VBC) => {
        const isRebateApp = appType === APP_TYPES.REBATE;
        const vbcApp = myApps.find((app) => app.id === appId && app.tenant_id === tenantId);
        if (userAccount && vbcApp) {
            navigate(
                `${
                    isRebateApp
                        ? AppConstant.ROUTE_PATHS.APPS_REBATE
                        : AppConstant.ROUTE_PATHS.APPS_VBC
                }/${tenantId}`,
                {
                    state: {
                        tenantId: tenantId,
                        organizationId: vbcApp.tenant?.organization_id,
                        appId: appId,
                        appName: vbcApp.custom_name,
                    },
                }
            );
        }
    };

    return { navigateToVBCApp };
};

export default useNavigateToVBCApp;
