import { Organization } from 'types';

export interface Customer {
    tenant_id: string;
    organization_id: number;
    invitation_status: string;
    invited_at: string;
    config: string;
    disabled: boolean;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    organization: Organization;
    apps: CustomerApp[];
}

export interface CustomerApp {
    id: number;
    tenant_id: string;
    app_name: string;
    custom_name: string;
    config: string;
    disabled: boolean;
}

export interface DrugLabel {
    id: number;
    tenant_id: string;
    app_name: string;
    custom_name: string;
    config: string;
    disabled: boolean;
    label: string;
}

export enum APP_TYPES {
    'VBC' = 'VBC',
    'REBATE' = 'Rebate',
}
export interface InviteCustomerPayload {
    name: string;
    ein: string | undefined;
    address_line_1: string | undefined;
    address_line_2: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zip_code: string | undefined;
    phone: string | undefined;
    primary_contact_email: string;
}

export interface InviteCustomerFormData {
    orgAdl1: string;
    orgAdl2: string;
    orgCity: string;
    orgEin: string;
    orgName: string;
    orgPhone: string;
    orgPriContact: string;
    orgState: string;
    orgZip: string;
}
