import { useIsPathNameMatching, useAppSelector } from 'hooks';

const AppName = () => {
    const { isPathMatching } = useIsPathNameMatching();

    const isVBC = isPathMatching('/apps/vbc');
    const isRebate = isPathMatching('/apps/rebate');
    const { appDetails } = useAppSelector((state) => state.vbc);

    if (!isVBC && !isRebate) {
        return <></>;
    }
    return <h5 className="me-3">{appDetails?.custom_name}</h5>;
};

export default AppName;
