import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getDefaultRequiredControlIds } from 'common';
import { Participant } from 'features/apps/vbc/types';
import { APP_TYPES } from 'features/customers/types';
import {
    ConfigurationIntakeFeature,
    DataIntakeFeatureType,
    ParticipantAccessType,
    TenantAppConfigurationInner,
    TenantAppDetail,
} from 'types';

interface VBCState {
    allParticipants: Participant[];
    tncAcceptedParticipants: Participant[];
    delegateParticipants: Participant[];
    currentParticipant: Participant | null;
    isContractingEntity?: boolean;
    isDelegate?: boolean;
    pendingPoliciesCount: number; // for health-plans all policies tab
    pendingReviewCount: number; // for tab in header
    appDetails: TenantAppDetail | null;
    activeConfiguration: TenantAppConfigurationInner | null;
    activeConfigIntakeFeatures: ConfigurationIntakeFeature[];
    accessType?: ParticipantAccessType;
    activeConfigClaims?: ConfigurationIntakeFeature;
    selectedActiveConfigIntakeFeature?: ConfigurationIntakeFeature;
    isVBCApp: boolean;
}

const initialState: VBCState = {
    allParticipants: [],
    tncAcceptedParticipants: [],
    delegateParticipants: [],
    currentParticipant: null,
    pendingPoliciesCount: 0,
    pendingReviewCount: 0,
    appDetails: null,
    activeConfiguration: null,
    activeConfigIntakeFeatures: [],
    activeConfigClaims: undefined,
    selectedActiveConfigIntakeFeature: undefined,
    isVBCApp: false,
};

const getIntakeFeatures = (config: TenantAppConfigurationInner) =>
    config.intakeFeatures.filter(
        (feat) => feat.enabled && feat.type === DataIntakeFeatureType.FILES_AND_DATA
    );

const getClaimsFeatures = (config: TenantAppConfigurationInner) =>
    config.intakeFeatures.find(
        (feat) => feat.enabled && feat.type === DataIntakeFeatureType.CLAIMS
    );

export const vbcSlice = createSlice({
    name: 'vbc',
    initialState,
    reducers: {
        setVBCParticipants: (state, { payload }: PayloadAction<Participant[]>) => {
            state.allParticipants = payload;
            state.tncAcceptedParticipants = payload.filter((pc) => pc.terms_conditions_accepted);
        },
        setDelegateParticipants: (state, { payload }: PayloadAction<Participant[]>) => {
            state.delegateParticipants = payload;
            state.isDelegate = payload.length > 0;
            state.accessType = payload.length > 0 ? ParticipantAccessType.DELEGATE : undefined;
        },
        setCurrentParticipant: (state, { payload }: PayloadAction<Participant | null>) => {
            state.currentParticipant = payload;
            state.isContractingEntity = !!payload;
            state.accessType = payload ? ParticipantAccessType.CONTRACTING_ENTITY : undefined;
        },
        setPendingPoliciesCount: (state, { payload }: PayloadAction<number>) => {
            state.pendingPoliciesCount = payload;
        },
        setPendingReviewCount: (state, { payload }: PayloadAction<number>) => {
            state.pendingReviewCount = payload;
        },
        setVBCAppDetail: (state, { payload }: PayloadAction<TenantAppDetail>) => {
            state.appDetails = payload;
            if (payload.config?.configurations?.length) {
                const activeConfig = payload.config.configurations[0];
                state.activeConfiguration = activeConfig;
                state.activeConfigIntakeFeatures = getIntakeFeatures(activeConfig);
                const claimsConfig = getClaimsFeatures(activeConfig);
                if (claimsConfig) {
                    claimsConfig.claimV2RequiredFields =
                        claimsConfig.claimV2RequiredFields || getDefaultRequiredControlIds();
                    state.activeConfigClaims = claimsConfig;
                } else {
                    state.activeConfigClaims = undefined;
                }
                state.selectedActiveConfigIntakeFeature = state.activeConfigIntakeFeatures?.[0];
            } else {
                state.activeConfiguration = null;
                state.activeConfigIntakeFeatures = [];
                state.activeConfigClaims = undefined;
                state.selectedActiveConfigIntakeFeature = undefined;
            }
        },

        setSelectedActiveConfigIntakeFeature: (state, { payload }: PayloadAction<string>) => {
            const selectedFeature = state.activeConfigIntakeFeatures.find(
                (feature) => feature.id === payload
            );
            state.selectedActiveConfigIntakeFeature = selectedFeature;
        },
        resetVBCState: () => {
            return { ...initialState };
        },
        setIsVBCApp: (state, { payload }: PayloadAction<TenantAppDetail>) => {
            state.isVBCApp = payload.app_name === APP_TYPES.VBC;
        },
    },
});

export const {
    setVBCParticipants,
    setCurrentParticipant,
    setPendingPoliciesCount,
    setPendingReviewCount,
    resetVBCState,
    setVBCAppDetail,
    setDelegateParticipants,
    setSelectedActiveConfigIntakeFeature,
    setIsVBCApp,
} = vbcSlice.actions;
export default vbcSlice.reducer;
